import React, { Fragment } from 'react';

function Instagram() {
    return (
        <Fragment>
            <a href="https://www.instagram.com/grandslipioffice/" target='_blank' rel='noreferrer'><img src='/image/icon/ig.png' alt='Logo Instagram' width="40" height="40"/></a>
        </Fragment>
    );
}

export default Instagram;