import React, { Fragment } from 'react'
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import Iframe from 'react-iframe'
import { useTranslation } from 'react-i18next'
// import $ from "jquery"
import * as emailjs from 'emailjs-com'
import swal from 'sweetalert'

function Content() {
    const { t } = useTranslation();
    function sendEmail(e) {
        e.preventDefault();
        
        emailjs.sendForm('service_iy8s2lf', 'template_k0at05w', e.target, '62ppzIfFkJBtojMAk')
        .then((result) => {
            swal(result.text, "Email Berhasil Terkirim", "success");
        }, (error) => {
            swal(error.text, "Email Gagal Terkirim", "warning");
        })
        e.target.reset()

    }
    return (
        <Fragment>
            <section className='contact'>
                <Container>
                    <div className='section-heading text-center mb-3 mb-lg-5 mb-md-4'>
                        <h2>{t('contact.a1')}</h2>
                    </div>
                    <div className='section-body'>
                        <Row>
                            <Col lg='6' md='6'>
                                <Iframe className="maps-kantor" tabIndex="0" allowFullScreen={true} aria-hidden="false" style={{border: "0"}} frameBorder="0" width="100%" height="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.477835708284!2d106.79589451476906!3d-6.200517695511265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6bef3b88d75%3A0x55f0db2cf392c2bf!2sGrand%20Slipi%20Tower%2C%20Palmerah%2C%20Kec.%20Palmerah%2C%20Kota%20Jakarta%20Barat%2C%20Daerah%20Khusus%20Ibukota%20Jakarta!5e0!3m2!1sid!2sid!4v1651200432509!5m2!1sid!2sid"/>
                            </Col>
                            <Col lg='6' md='6'>
                                <Form className="form-contact contact_form" id="contactForm" validated={false} onSubmit={sendEmail}>
                                    <Form.Group>
                                        <Form.Control type="text" name="name" placeholder={t('contact.name')} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control type="text" name="phone" placeholder={t('contact.telepon')} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control type="email" name="email" placeholder={t('contact.email')} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control as="textarea" name="message" placeholder={t('contact.message')} />
                                    </Form.Group>
                                    <Form.Group className='my-5 text-center'>
                                        <Button type="submit" className="btn btn-consult" variant='default'>{t('contact.submit')}</Button>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col lg='12' className="mt-5">
                                <Row>
                                    <Col lg='4' md='4' className='mt-3 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 mt-xs-3'>
                                        <a href='https://www.google.com/maps/place/Apartemen+Slipi/@-6.200685,106.798265,16z/data=!4m5!3m4!1s0x0:0xd13c0396fb440f14!8m2!3d-6.2006851!4d106.7982654?hl=id' target='_blankk' title='Addess' rel='noreferrer' className='text-black'>
                                            <Card className='h-100 py-lg-4 py-md-3 px-lg-4 px-md-2'>
                                                <div className='box-img'>
                                                    <Card.Img src='/image/icon/location-black.png' alt="icon location" className='mb-3 black' width='34' height='45'/>
                                                    <Card.Img src='/image/icon/location-white.png' alt="icon location" className='mb-3 white' width='34' height='45'/>
                                                </div>
                                                <Card.Body>
                                                    <Card.Text>
                                                        Jl. Letjen S. Parman No.1, RW.4, Palmerah, Kec. Palmerah, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11480
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </a>
                                    </Col>
                                    <Col lg='4' md='4' className='mt-3 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 mt-xs-3'>
                                        <a href='tel:0215301111' target='_blank' title='Phone' rel='noreferrer' className='text-black'>
                                            <Card className='h-100 py-lg-4 py-md-3 px-lg-4 px-md-2'>
                                                <div className='box-img'>
                                                    <Card.Img src='/image/icon/phone-black.png' alt="icon phone" className='mb-3 black' width='29' height='40'/>
                                                    <Card.Img src='/image/icon/phone-white.png' alt="icon phone" className='mb-3 white' width='29' height='40'/>
                                                </div>
                                                <Card.Body>
                                                    <Card.Text>
                                                        021-5301111 - ext 4
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </a>
                                    </Col>
                                    <Col lg='4' md='4' className='mt-3 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 mt-xs-3'>
                                        <a href='mailto:hi.grandslipi@gmail.com' target='_blank' title='Email' rel='noreferrer' className='text-black'>
                                            <Card className='h-100 py-lg-4 py-md-3 px-lg-4 px-md-2'>
                                                <div className='box-img'>
                                                    <Card.Img src='/image/icon/mail-black.png' alt="icon mail" className='mb-3 black' width='44' height='33'/>
                                                    <Card.Img src='/image/icon/mail-white.png' alt="icon mail" className='mb-3 white' width='44' height='33'/>
                                                </div>
                                                <Card.Body>
                                                    <Card.Text>
                                                        hi.grandslipi@gmail.com
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Fragment>
    );
}

export default Content;