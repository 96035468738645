import React, { Fragment, useState } from 'react'
import { Container, Row, Col, Form, Button} from 'react-bootstrap'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom";

export default function TestimoniStore() {
	const history = useHistory();
	const { t } = useTranslation();
	const [name, setName] = useState();
    const [message, setMessage] = useState();
	const handleSubmit= (e) => {
		e.preventDefault();
		const data = {
			'custName': name,
			'message': message
		}
		axios.post(process.env.REACT_APP_API + `testimoni/store`, data)
		.then((response) => {
			history.push("/testimoni");
		}, (error) => {
			console.log(error);
		});
	}
	return (
		<Fragment>
			<section className='testimoni'>
				<Container>
					<div className='section-heading'>
						<h2>{t('testimoni.send')}</h2>
					</div>
					<div className='section-body mt-4'>
						<Row>
							<Col xl='8' lg='8' md='12' sm='12' xs='12' className='mx-auto'>
								<Form className="form-testimoni" id='formtestimoni' validated={false} onSubmit={e => {handleSubmit(e)}}>
									<Form.Group className='pb-2'>
										<label className='control-label'>Name</label>
										<Form.Control type="text" name="name" placeholder='Name' onChange={e => setName(e.target.value)} />
									</Form.Group>
									<Form.Group className='pt-2'>
										<label className='control-label'>Message</label>
										<Form.Control as="textarea" name="message" placeholder="Message" rows={5} onChange={e => setMessage(e.target.value)}/>
									</Form.Group>
									<Form.Group className='my-5 text-center'>
										<Button type="submit" className="btn btn-consult" variant='default'>{t('contact.submit')}</Button>
									</Form.Group>
								</Form>
							</Col>
						</Row>
					</div>
				</Container>
			</section>
		</Fragment>
	)
}
