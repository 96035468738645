import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Sosmed from '../partials/Sosmed'

// content
import Hero from '../content/about/Hero'
import About from '../content/about/About'

function AboutUs() {
    return (
        <Fragment>
            <Helmet>
                <link rel="canonical" href={process.env.REACT_APP_STAGING + 'about-us'} />
                <link rel='shortlink' href={process.env.REACT_APP_STAGING + 'about-us'} />
                {/* Primary Meta Tags */}
                <title>Grand Slipi Tower</title>
                <meta name="title" content="Grand Slipi Tower"/>
                <meta name="description" content="Grand Slipi Tower"/>
                <meta name="keywords" content="Grand Slipi Tower"/>

                <meta itemprop="title" content="Grand Slipi Tower"/>
                <meta itemprop="description" content="Grand Slipi Tower"/>
                <meta itemprop="image" content="/fav-icon-gst.png"/>

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={process.env.REACT_APP_STAGING + 'about-us'}/>
                <meta property="og:title" content="Grand Slipi Tower"/>
                <meta property="og:description" content="Grand Slipi Tower"/>
                <meta property="og:image" content="/fav-icon-gst.png"/>

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={process.env.REACT_APP_STAGING + 'about-us'}/>
                <meta property="twitter:title" content="Grand Slipi Tower"/>
                <meta property="twitter:description" content="Grand Slipi Tower"/>
                <meta property="twitter:image" content="/fav-icon-gst.png"/>
                
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <meta name="googlebot-news" content="index, follow"/>
            </Helmet>
            <Sosmed/>
            <h1 className="firstHeading MSI_show_element">About Us - Grand Slipi Tower Apartment in Slipi</h1>
            <Hero/>
            <About/>
        </Fragment>
    );
}

export default AboutUs;