import React, { Fragment, useEffect, useState } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

export default function Testimoni() {
	const { t } = useTranslation();
	const [moretestimoni, listMoreTestimoni] = useState([]);
    useEffect( () => {
        const url = process.env.REACT_APP_API + `testimoni`
        axios.get(url)
        .then(res => {
            listMoreTestimoni(res.data)
        })
        .catch(error => console.log(error))
    },[]);
	return (
		<Fragment>
			<section className='testimoni'>
				<Container>
					<div className='section-heading d-flex align-items-center justify-content-between'>
						<h2>{t('testimoni.title')}</h2>
						<a href='/testimoni/store' className='btn btn-sm btn-consult' >{t('testimoni.send')}</a>
					</div>
					<div className='section-body mt-4'>
						<Row>
							{
								moretestimoni.length > 0 ? moretestimoni.map((data, i) => {
									return(
										<Col xl='4' lg='4' md='6' sm='12' xs='12' className='py-2' key={i}>
											<Card className='h-100' id="card-testimonmi" key={i}>
												<Card.Header>
													<p>{data.customerName}</p>
													<span>{data.customerAs}</span>
												</Card.Header>
												<Card.Body>
													<Card.Text>
														{data.message}
													</Card.Text>
												</Card.Body>
											</Card>
										</Col>
									)
								}) : 
								'data kosong'
							}
						</Row>
					</div>
				</Container>
			</section>
		</Fragment>
	)
}
