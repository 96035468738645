import React, { Fragment, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
// import Skeleton from 'react-loading-skeleton'
import Sosmed from '../partials/Sosmed'
import striptags from 'striptags'
import { Container, Row, Col } from 'react-bootstrap'
import { FacebookShareButton,TwitterShareButton,WhatsappShareButton,FacebookIcon,TwitterIcon,WhatsappIcon } from 'react-share'
import OtherContent from '../content/news/OtherContent'

function DetailNews(props) {
    const params = props.match.params.slug;
    const [article, listArticle] = useState([]);
    useEffect(() => {
        const url = process.env.REACT_APP_API + `article/` + params
        axios.get(url)
        .then(res => {
            listArticle(res.data)
        })
        .catch(error => console.log(error))
    },[]);
    return (
        <Fragment>
            <Helmet>
                <link rel="canonical" href={process.env.REACT_APP_STAGING + 'news/' + article.slug} />
                <link rel='shortlink' href={process.env.REACT_APP_STAGING + 'news/' + article.slug} />
                {/* Primary Meta Tags */}
                <title>Grand Slipi Tower</title>
                <meta name="title" content={article.title}/>
                <meta name="description" content={striptags(article.content).slice(0, 170)}/>
                <meta name="keywords" content={article.title + ',' + article.tags}/>

                <meta itemprop="title" content={article.title}/>
                <meta itemprop="description" content={striptags(article.content).slice(0, 170)}/>
                <meta itemprop="image" content={article.thumbImage && article.thumbImage.medium}/>

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={"/news/" + article.slug}/>
                <meta property="og:title" content={article.title}/>
                <meta property="og:description" content={striptags(article.content).slice(0, 170)}/>
                <meta property="og:image" content={article.thumbImage && article.thumbImage.medium}/>

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={"/news/" + article.slug}/>
                <meta property="twitter:title" content={article.title}/>
                <meta property="twitter:description" content={striptags(article.content).slice(0, 170)}/>
                <meta property="twitter:image" content={article.thumbImage && article.thumbImage.medium}/>
                
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <meta name="googlebot-news" content="index, follow"/>
            </Helmet>
            <Sosmed/>
            <section className='detail-article'>
                <Container>
                    <div className="section-heading">
                        <h1 className='text-center'>{article.title}</h1>
                        <p className='author'>{article.author + " · " + article.published_human}</p>
                    </div>
                    <div className='section-body'>
                        <Row>
                            <Col xl='12' lg='12' md='12' sm='12'>
                                <div className="thumbnail text-center mb-3 mb-lg-4">
                                    <img className="img-fluid center" src={article.thumbImage && article.thumbImage.large} alt={article.title} width='696' height='391' />
                                </div>
                                <div className='content' dangerouslySetInnerHTML={{__html: article.content}} />
                                <div className="share d-flex align-items-center mt-5">
                                    <p>Bagikan :</p>
                                    <ul className="list-unstyled d-flex align-items-center mb-0">
                                        <li className='ms-3'>
                                            <WhatsappShareButton title={article.title} url={'https://uat.permatapuricibubur.com/news/' + article.slug }>
                                                <WhatsappIcon size={40} round={true} />
                                            </WhatsappShareButton>
                                        </li>
                                        <li className='ms-3'>
                                            <FacebookShareButton title={article.title} quote={article.title} hashtag={article.tags} url={'https://uat.permatapuricibubur.com/news/' + article.slug }>
                                                <FacebookIcon size={40} round={true} />
                                            </FacebookShareButton>
                                        </li>
                                        <li className='ms-3'>
                                            <TwitterShareButton title={article.title} url={'https://uat.permatapuricibubur.com/news/' + article.slug }>
                                                <TwitterIcon size={40} round={true} />
                                            </TwitterShareButton>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <OtherContent/>
                    </div>
                </Container>
            </section>
        </Fragment>
    );
}

export default DetailNews;