import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function Footer() {
    const { t } = useTranslation();
    return (
        <Fragment>
            <footer>
                <div className='footer-top py-5'>
                    <Container>
                        <Row className="justify-content-between">
                            <Col lg="3" md="12" sm="12">
                                <ul className='list-unstyled contact'>
                                    <li>
                                        <a href="tel:0215301111" className='d-flex align-items-center text-white' target='_blank' rel='noreferrer'><img src="/image/icon/f-phone.png" alt="phone" className="img-fluid" width='25' height='24' /><span className='ms-2'>021-5301111</span></a>
                                    </li>
                                    <li>
                                        <a href="mailto:hi.grandslipi@gmail.com" className='d-flex align-items-center text-white' target='_blank' rel='noreferrer'><img src="/image/icon/f-mail.png" alt="email" className="img-fluid" width='25' height='24' /><span className='ms-2'>hi.grandslipi@gmail.com</span></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/grandslipioffice/" className='d-flex align-items-center text-white' target='_blank' rel='noreferrer'><img src="/image/icon/f-ig.png" alt="instagram" className="img-fluid" width='25' height='24' /><span className='ms-2'>Grand slipi</span></a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/grandslipitower" className='d-flex align-items-center text-white' target='_blank' rel='noreferrer'><img src="/image/icon/f-fb.png" alt="facebook" className="img-fluid" width='25' height='24' /><span className='ms-2'>Grand slipi</span></a>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg="3" md="12" sm="12" className='py-lg-0 py-md-5 py-5'>
                                <ul className='list-unstyled menu'>
                                    <li>
                                        <a href="/about-us" className="text-white">{t('menu.aboutus')}</a>
                                    </li>
                                    <li>
                                        <a href="/floor-plan" className="text-white">{t('menu.floorplan')}</a>
                                    </li>
                                    <li>
                                        <a href="/our-space" className="text-white">{t('menu.ourspace')}</a>
                                    </li>
                                    <li>
                                        <a href="/benefit" className="text-white">{t('menu.benefit')}</a>
                                    </li>
                                    <li>
                                        <a href="/news" className="text-white">{t('menu.news')}</a>
                                    </li>
                                    <li>
                                        <a href="/contact-us" className="text-white">{t('menu.contact')}</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg="3" md="12" sm="12">
                                <div className="support text-lg-center text-left">
                                    <p className='mb-4'>Developed by</p>
                                    <img src='/logo-pp.png' alt='pp properti' className='img-fluid' width="57" height="70" />
                                </div>
                            </Col>
                        </Row>
                        <p className='mt-5 informasi'>{t('footer')}</p>
                    </Container>
                </div>
                <hr/>
                <div className='footer-bottom copyright text-center'>
                    <p>Grand Slipi Tower © 2021 - Powered by <a href='https://imajinative.agency/' target="_blank" rel='noreferrer'>Imajinative</a></p>
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;